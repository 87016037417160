/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useState} from 'react'
import {KTSVG} from '../../../_isme/helpers'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../_isme/layout/core'

const PrivacyPage: React.FC = () => {
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('Header')
  const [config, setConfig] = useState<ILayout>(getLayout())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)

  const updateData = (fieldsToUpdate: Partial<ILayout>) => {
    const updatedData = {...config, ...fieldsToUpdate}
    setConfig(updatedData)
  }

  const updateConfig = () => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
    } catch (error) {
      setConfig(getLayout())
    }
    setTimeout(() => {
      setLayout(config)
      setConfigLoading(false)
    }, 1000)
  }

  const reset = () => {
    setResetLoading(true)
    setTimeout(() => {
      setConfig(getLayout())
      setResetLoading(false)
    }, 1000)
  }

  return (
    <>
      <div className='card mb-10'>
        <div className='card-body d-flex align-items-center py-8'>
          {/* begin::Icon */}
          <div className='d-flex h-80px w-80px flex-shrink-0 flex-center position-relative'>            
            <KTSVG
              path='/media/icons/duotune/coding/privacy-policy.svg'
              className='svg-icon-6x svg-icon-primary position-absolute'
            />
          </div>
          {/* end::Icon */}

           {/* begin::Description */}
           <div className='ms-6'>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            PRIVACY POLICY
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            LAST UPDATED: DEC 2, 2022.
            </p>
          </div>
          {/* end::Description */}
        </div>
      </div>
      <div className='card card-custom'>
        

        {/* begin::Form */}
        <form className='form'>
          {/* begin::Description */}
          <div className='ms-6'>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            INTRODUCTION
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            Welcome to Isme, a free, open-source, client-side tool for interacting with the blockchain. Isme values privacy and we make a concerted effort to minimize and, to the extent possible, eliminate the exposure of personal data during the use of our products. Isme does not collect, hold, or store keys, account information, or passwords. We do not monetize the collection of data and do not use your data for marketing or advertising.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            To the extent we collect any personal information, this privacy policy (“Policy”) describes how Isme collects, uses, and shares personal information of people who visit our website, www.isme.one.com (the “Site”),
            use our mobile applications, Isme wallet and Ismeconnect (the “Apps”), or otherwise use our services (collectively, the “Services”).
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            This Policy applies to anyone who accesses the Services. Please read the Policy carefully to understand our practices regarding your information and how we will treat it. By visiting the Site, Blog, Apps, and/or using the Services, you acknowledge that the collection, use, and sharing of your information will take place as described in this Policy.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            So that we are clear about the terminology we are using, when we use the phrase “Personal Information” in this Privacy Policy, we mean information about an individual that (either by itself or when combined with information from other available sources) allows that individual to be identified, including but not limited to, the individual’s name, telephone number, or e-mail address.
            </p>
            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            THE BLOCKCHAIN
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            Due to the inherent transparency of many blockchains, including the Ethereum Blockchain, transactions that individuals broadcast via Isme may be publicly accessible. This includes, but is not limited to, your public sending address, the public address of the receiver, the amount sent or received, and any other data a user has chosen to include in a given transaction. Information stored on a blockchain may be public, immutable, and difficult or even impossible to remove or delete. Transactions and addresses may reveal information about the user’s identity and information can potentially be correlated now or in the future by any party who chooses to do so, including law enforcement. Users are encouraged to review how privacy and transparency on the blockchain works.
            </p>
           
            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            WHAT WE COLLECT
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We collect information about you as described below. We use this information to enhance your experience with our Services.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We may collect Personal Information you choose to provide to us. For example, when you contact us for support through the Services, you give us your e-mail address and any other information that you choose to provide. If you choose to join our mailing list or subscribe to our email newsletter, you provide your email address. Also, if you participate in a Isme offer, give-away, or promotion (“Promotion”), you provide your name, e-mail address, mailing address, and any other required information.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We may however collect information regarding your browser, operating system, visiting time, actions and page views per visit, and referring site information.
            </p>
            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            USE OF INFORMATION
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We use the information that we have about you to provide support and certain Services to you.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We may use the Personal Information we collect from and about you to (1) present our products and the Services to you; (2) provide you with information or services that you request from us, including to respond to your comments, questions, and/or provide customer service; 3) monitor and analyze usage and trends and personalize and improve the Services and your experience using the Services; (4) send email newsletters and marketing emails, from which you can opt-out as described below; and (5) for any other purpose with your consent.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We may use your information to contact you about our products and services that you request and/or that may be of interest to you. Our email newsletter and marketing emails tell you how to opt-out or unsubscribe. If you opt-out of marketing emails, we may still send you non-marketing emails and notifications, such as responses to your support or customer service requests.
            </p>


            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            SHARING OF PERSONAL INFORMATION
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We will not disclose your Personal Information other than as described below, and we do not and will not sell your Personal Information to anyone.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We may share the Personal Information we collect from and about you (1) to fulfill the purpose for which you provided it; (2) with your consent; (3) for legal, protection, and safety purposes; (4) to comply with any court order, law, or legal process, including to respond to any government or regulatory request; (5) to protect the rights of Isme, our agents, customers, and others, including by enforcing our agreements, policies, and terms of service; and (6) with those who need it to do work for us (our Service Providers, as defined below).
             </p>

            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            SERVICE PROVIDERS
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We may contract with third parties to perform functions related to the Services (“Service Providers”). In general, Service Providers will have access to your Personal Information only to the extent needed to perform their business functions but may not use or share that personal information for purposes outside the scope of their functions related to the Services.
            </p>

            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            LINKS TO OTHER SITES
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            The Services contain links to other third-party websites or applications. Once you click on such a link and leave the Site or are redirected to a third-party website or application, you are no longer governed by this Policy. Any information you provide on those sites is subject to that third party’s privacy policy and we are not responsible for the privacy and security practices and policies of those third-party sites or applications.
            </p>

            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            OUR COMMITMENT TOWARDS CHILDREN'S PRIVACY
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We do not direct the Services to, nor do we knowingly collect any Personal Information from children under 13. Children under 13 are not eligible to use the Services. If we learn that someone using our Services is under 13 years of age, we will take steps to remove any Personal Information from our database and to prevent them from utilizing the Services.
            </p>

            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            SECURITY OF YOUR PERSONAL INFORMATION & RIGHTS
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We are committed to protecting the security of Personal Information. We have taken certain physical, administrative, and technical steps to help safeguard the information we collect from and about you. While we take steps to help ensure the integrity and security of our network and systems, we cannot guarantee our security measures.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            In certain circumstances, you will also have the following rights: 
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            1. Right to access: the right to request certain information about, access to and copies of any Personal Information about you that we are holding (please note that you are entitled to request one copy of the Personal Information that we hold about you at no cost, but for any further copies, we reserve the right to charge a reasonable fee based on administration costs);
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            2. Right to rectification: the right to have your Personal Information rectified if it is inaccurate or incomplete;
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            3. Right to erasure/“right to be forgotten”: where the processing of your information is based on your consent, the right to withdraw that consent and the right to request that we delete or erase your Personal Information from our systems (however, this will not apply if we are required to hold on to the information for compliance with any legal obligation, or if we require the information to establish or defend any legal claim);
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            4. Right to restriction of use of your information: the right to stop us from using your Personal Information or limit the way in which we can use it;
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            5. Right to data portability: the right to request that we return any information you have provided in a structured, commonly used, and machine-readable format, or that we send it directly to another company, where technically feasible; and
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            6. Right to object: the right to object to our use of your Personal Information including where we use it for our legitimate interests.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            Requests in relation to the above rights should be sent to:
            </p>
            <a href='mailto:support@isme.one' className='text-muted text-hover-primary px-2'>
            support@isme.one
            </a>

            <br></br>
            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            LEGAL BASIS FOR PROCESSING
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            The following legal bases apply to the ways in which we use and share an individual’s Personal Information: 
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            1. We rely on an individual’s consent to process Personal Information to provide support and/or carry out Promotions. This consent can be withdrawn at any time.
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            2. We also process the information provided by an individual in our legitimate interests in ensuring our business is conducted legitimately and to a high standard.
            </p>

            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            RETENTION 
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            If you subscribe to our mailing list, we will retain the information you provide as long as you remain subscribed. 
            </p>
            <br></br>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            For other information you provide, we will retain such information in order to process your request, provide support, and/or carry out and fulfill our Promotions. If you request support, we will retain your information for no longer than six (6) months. If you participate in a Promotion, we will retain your information until the Promotion is carried out and ninety (90) days after any prize, reward, or offer is fulfilled. Your Personal Information will then be deleted.</p>
            <br></br>


            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            CONTACT INFORMATION 
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We welcome your comments or questions about this Policy. You may contact us at:  
            </p>
            <a href='mailto:support@isme.one' className='text-muted text-hover-primary px-2'>
            support@isme.one
            </a>

            <br></br>
            <br></br>
            <p className='text-gray-800 text-hover-primary fs-2 fw-bold me-1'>
            CHANGES TO PRIVACY POLICY
            </p>
            <p className='list-unstyled text-gray-600 fw-semibold fs-6 p-0 m-0'>
            We may modify this Policy from time to time. If we make any changes, we will change the Last Updated date above. We also may provide additional notice, as applicable, depending on the type of change. If you object to any changes, you may stop using the Services. Your continued use of the Services after we publish or otherwise provide notice about our changes to the Policy means that you are consenting to the updated Policy.
            </p>
            <br></br>

          </div>
          
          {/* end::Description */}
        </form>
        {/* end::Form */}
      </div>
    </>
  )
}

export {PrivacyPage}
