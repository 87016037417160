import {FC} from 'react'
import {PageTitle} from '../../../_isme/layout/core'
import {PrivacyPage} from './PrivacyPage'

const PrivacyPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Privacy</PageTitle>
      <PrivacyPage />
    </>
  )
}

export default PrivacyPageWrapper
